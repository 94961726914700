import React from "react";
import Affiliate from "./Affiliate";
import Form from "./Form";
import AffiliateBenefits from "./AffiliateBenefits";
import { AccountBalanceWalletTwoTone } from "@mui/icons-material";

const Affiliates = () => {

    return (
        <>
            <section className="section section-bottom-0 bg-light !bg-transparent !mt-6 lg:!mt-5 min-h-full">
                <div className="container">
                    <div className="section-head">
                        <div className="flex flex-col gap-5 items-center">
                            <div className="h-20 w-20 text-4xl hidden lg:flex items-center justify-center rounded-full overflow-hidden relative">
                                <div className="absolute left-0 top-0 w-full h-full bg-primary opacity-[0.3]"></div>
                                <AccountBalanceWalletTwoTone className="text-primary !text-4xl" />
                            </div>
                            <div className="px-2 lg:px-0">
                                <h1 className="title h1 !text-4xl sm:!text-5xl tracking-[-1px] !text-gray-800 !-mt-1 text-center">Best Affiliate Product To Promote</h1>
                                <p className="text-lg mt-3 text-center">ChatBros Affiliate Marketing Program</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AffiliateBenefits />
            <Affiliate />
            <Form />
        </>
    );
}

export default Affiliates;