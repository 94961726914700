import React from "react";
import CallToAction from "./CallToAction";
import { HiChevronRight } from "react-icons/hi";

const Footer = () => {
    return (
        <footer className="nk-footer">
            <section className="is-dark" style={{ backgroundImage: "linear-gradient(120deg, #323945 78%, #514A65 100%)" }}>
                <div className="w-full section" style={{ backgroundImage: "url(/images/footer-bg-shapes.svg)", backgroundPosition: "50% 100px" }}>
                    <div className="container relative">
                        <div className="md:grid grid-cols-2 text-white mb-6">
                            <h2 className="text-center md:text-left text-5xl text-white font-bold">Join <span className="text-gradient-primary">Chatbros</span> and start growing your online business.</h2>
                            <div className="w-full text-center md:text-right">
                                <a href="https://app.chatbros.ai" className="btn !bg-gray-50 rounded-pill !font-bold !text-base py-3 px-5 mt-5 !text-lg !text-gray-700 !mx-auto inline-block inline-flex items-center gap-4">
                                    <span>Get Started Now!</span>
                                    <HiChevronRight className="text-2xl" />
                                </a>
                            </div>
                        </div>
                        <hr className="border-opacity-25 border-white m-0" />
                        <div className="py-5">
                            <div className="row">
                                <div className="col-md">
                                    <p className="mb-2 mb-md-0 text-center lg:!text-left">&copy; {new Date().getFullYear()} Reblox Solutions LLC.</p>
                                </div>
                                <div className="col-md">
                                    <ul className="list list-row gx-4 justify-content-center justify-content-md-end">
                                        <li><a href="/affiliates" className="text-white">Affiliate Program</a></li>
                                        <li><a href="/terms" className="text-white">Terms</a></li>
                                        <li><a href="/privacy" className="text-white">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer;