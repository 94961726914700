import { AddTwoTone, RemoveTwoTone } from "@mui/icons-material";
import React, { useState } from "react";

const FAQItem = (props) => {

    const {
        question,
        answer
    } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    return (
        <div className="md:px-4">
            <div 
                onClick={() => setIsOpen(!isOpen)}
                className={`cursor-pointer rounded-full w-full !px-3 md:!px-5 py-[1.2rem] bg-gray-100 !text-gray-600 font-semibold text-sm md:text-base flex items-center justify-between !transition-all hover:shadow-lg hover:translate-y-[-2px] gap-4 ${isOpen && `!bg-white !shadow-xl !text-gray-800`}`}
            >
                <span className="overflow-hidden whitespace-nowrap text-ellipsis block flex-1">{question}</span>
                {isOpen
                    ? <RemoveTwoTone className="!text-green-600 text-xl" />  
                    : <AddTwoTone className="!text-green-600 text-xl" />
                }
            </div>
            <div className={`transition-all overflow-hidden scale-[1] !h-0 px-4 ${isOpen && `!h-auto !scale-[1] pt-3 pb-5`}`}>
                {answer}
            </div>
        </div>
    );
}

export default FAQItem;