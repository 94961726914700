import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const BlogPage = () => {

    const [ isLoading, setIsLoading ] = useState(true);
    const [ page, setPage ] = useState();
    const [ others, setOthers ] = useState();
    const navigate = useNavigate();
    const { slug } = useParams();

    const url = `/blog`;
    const collection = `blogCollection`;
    const fallbackURL = `/blog`;
    const query = `
        query {
            ${collection}(where: {slug: "${slug}"}) {
                items {
                    title
                    description
                    keywords
                    slug
                    previewImage {
                        url
                    }
                    content {
                        json
                    }
                    sys {
                      publishedAt
                    }
                }
            }
        }
    `;
    const queryOther = `
        query {
            ${collection} {
                items {
                    title
                    description
                    keywords
                    slug
                    previewImage {
                        url
                    }
                    content {
                        json
                    }
                    sys {
                      publishedAt
                    }
                }
            }
        }
    `;

    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/aov9i9sivcfe/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer KrDZdPY-NQiE_mxMlnmioRCZo5nbMh950f3b-D9tgbM",
            },
            body: JSON.stringify({ query: query }),
        }).then((response) => {
            return response.json()
        }).then(({ data, errors }) => {
            if (errors) navigate(fallbackURL);
            if (!data?.[collection]?.items?.length) navigate(fallbackURL);
            if (!errors) setPage(data?.[collection]?.items[0]);
        }).catch(() => {
            navigate(fallbackURL);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/aov9i9sivcfe/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer KrDZdPY-NQiE_mxMlnmioRCZo5nbMh950f3b-D9tgbM",
            },
            body: JSON.stringify({ query: queryOther }),
        }).then((response) => {
            return response.json()
        }).then(({ data, errors }) => {
            if (!errors) setOthers(data?.[collection]?.items);
        }).catch(() => {

        }).finally(() => {
            setIsLoading(false);
        });
    }, []);
    
    if (isLoading) {
        return (
            <div className='h-[110vh] w-full flex flex-col items-center justify-center gap-4'>
                <img src="/assets/images/logo.png" className='w-[125px]' />
                <span>Loading...</span>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>{page?.title}</title>
                <meta name="description" content={page?.description} />
                <meta name="keywords" content={page?.keywords?.join(",")} />
                <meta property="og:image" content={page?.previewImage?.url} />
                <meta property="image" content={page?.previewImage?.url} />
                <meta name="twitter:image" content={page?.previewImage?.url} />
            </Helmet>
            <div>
                <div className="bg-lucecitas bg-bottom bg-no-repeat pt-[82px] pb-[80px] lg:pt-[120px]">
                    <div className="relative">
                        <div className="container">
                            <div className="items-center py-10 md:flex md:h-[400px] mb-8 md:py-0 gap-10">
                                <div className="heading relative mb-0 md:w-[700px] flex-1">
                                    <h4 className="!text-gray-800 font-bold !text-4xl lg:!text-6xl">{page?.title}</h4>
                                    <p className="relative mt-4 font-medium text-gray-600 before:absolute before:top-0 before:h-full before:w-1 before:bg-secondary ltr:pl-8 ltr:before:left-0 rtl:pr-8 rtl:before:right-0 !text-lg">
                                        {page?.description}
                                    </p>
                                </div>
                                {!!page?.previewImage?.url && <img src={page?.previewImage?.url} className='rounded-2xl shrink-0 w-full !drop-shadow drop-shadow-white aspect-square object-cover max-w-[450px]' />}
                            </div>
                        </div>
                    </div>
                </div>

                <section className="bg-gradient-to-t from-white/[54%] to-transparent py-14 dark:bg-none lg:py-[100px]">
                    <div className='container grid grid-cols-6 gap-6'>
                        <div className="col-span-4 flex-1 flex flex-col gap-4 compare-section">
                            {documentToReactComponents(page?.content?.json)}
                        </div>
                        {!!others?.length && <div className='col-span-2 flex flex-col gap-4 shrink-0'>
                            <h2 className='text-2xl font-semibold !text-gray-800'>Related blogs</h2>
                            {others?.map((value) => ({ ...value, sort: Math.random() }))?.sort((a, b) => a.sort - b.sort)?.slice(0, Math.min(5, others?.length))?.map((item) => (
                                <div onClick={() => window.location.href = `${url}/${item?.slug}`} className='flex items-center gap-0 w-full drop-shadow-2xl rounded-xl overflow-hidden bg-[#fafafa] cursor-pointer transition-all hover:bg-[#fafafa] hover:!translate-y-[-5px]'>
                                    <img src={item?.previewImage?.url} className='rounded-lg w-[91px] h-[91px] object-cover aspect-square' />
                                    <div className='flex flex-col gap-1 w-full overflow-hidden px-4 py-2'>
                                        <p className='font-semibold w-full text-base !text-gray-700 whitespace-nowrap text-ellipsis text-overflow-ellipsis ellipsis overflow-hidden'>{item?.title}</p>
                                        <p className='w-full overflow-hidden line-clamp-2 text-sm'>{item?.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    </div>
                </section>
            </div>
        </>
    );
};

export default BlogPage;
