import React, { useState } from "react";
import SinglePricingCard from "../../components/SinglePricingCard";
import { AiFillCreditCard } from "react-icons/ai";
import Switch from "react-switch";
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import { BrandingWatermarkTwoTone, ChatTwoTone, CheckTwoTone, CloseTwoTone, PublicTwoTone, QuestionAnswerTwoTone, SchoolTwoTone, SupportAgentTwoTone, TravelExploreTwoTone } from "@mui/icons-material";

export const pricingTable = [
    {
        popular: false,
        name: `Standard Plan`,
        description: `Ideal for small businesses, perfect for getting started with AI chatbots.`,
        price: {
            month: 15,
            year: 120 / 12,
        },
        chatbros: `1 Chatbro`,
        conversations: `500 conversations / mo`,
        web_pages: `50 Data Sources`,
        watermark: false
    },
    {
        popular: true,
        name: `Growth Plan`,
        description: `Great for expanding businesses, unlock Chatbros' advanced features.`,
        price: {
            month: 49,
            year: 384 / 12,
        },
        chatbros: `3 Chatbros`,
        conversations: `2,000 conversations / mo`,
        web_pages: `500 Data Sources`,
        watermark: false
    },
    {
        popular: false,
        name: `Business Plan`,
        description: `Tailored for growing enterprises, comprehensive AI solutions for larger teams.`,
        price: {
            month: 199,
            year: 1548 / 12,
        },
        chatbros: `10 Chatbros`,
        conversations: `10,000 conversations / mo`,
        web_pages: `2,000 Data Sources`,
        watermark: true
    },
    {
        popular: false,
        name: `Enterprise Plan`,
        description: `Designed for large corporations, scale your business with premium features.`,
        price: {
            month: 999,
            year: 7908 / 12,
        },
        chatbros: `Unlimited Chatbros`,
        conversations: `Unlimited conversations`,
        web_pages: `10,000 Data Sources`,
        watermark: true
    }
]

const Pricing = () => {

    const [checked, setChecked] = useState(true);

    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    return (
        <section className="section section-bottom-0 min-h-full relative" id="pricing" style={{ backgroundImage: "linear-gradient(180deg, #DBE4FE 0%, #fff 50%, #fff 100%)" }}>
            {/* <svg className="h-44 w-[300%] absolute left-0 top-[-1px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#fff"><path class="lqd-shape-fill" d="M738,99l262-93V0H0v5.6L738,99z"></path></svg> */}
            <div className="container mt-20">
                <div className="flex flex-col lg:flex-row items-start justify-between space-y-10 lg:space-y-0">
                    <div className="w-full">
                        <div className="section-head !flex flex-col gap-7 md:gap-0 md:flex-row justify-between w-full mb-0 pb-0">
                            <div className="flex gap-5 items-center flex-1">
                                <div className="h-20 w-20 text-3xl hidden lg:flex items-center justify-center rounded-full overflow-hidden relative">
                                    <div className="absolute left-0 top-0 w-full h-full bg-primary opacity-[0.3]"></div>
                                    <AiFillCreditCard className="text-primary" />
                                </div>
                                <div className="px-2 lg:px-0">
                                    <h1 className="title h1 !text-4xl sm:!text-4xl tracking-[-1px] !text-gray-800 !-mt-1">Simple Pricing</h1>
                                    <p className="text-base -mt-3">Choose a plan and start boosting your brand with ChatBros today!</p>
                                </div>
                            </div>
                            <div className="shrink-0 flex justify-center items-center gap-3 text-gray-800 relative mr-10 text-base">
                                <span onClick={() => setChecked(false)} className="cursor-pointer">Monthly</span>
                                <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    className="react-switch"
                                    handleDiameter={30}
                                    width={70}
                                    height={35}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    onColor="#4ac193"
                                />
                                <span onClick={() => setChecked(true)} className={`cursor-pointer font-bold ${checked ? `text-[#4ac193]` : `text-gray-400`}`}>Annually</span>
                                <div className="pointer-events-none animate-6 absolute flex -top-7 md:-top-4 right-2 md:-right-6">
                                    <span className="block -mt-5 -mr-0 font-[500] text-gray-600">Save 35%</span>
                                    <img src="/images/price-arrow.png" className="grayscale brightness-[0.3] w-[20px]" />
                                </div>
                            </div>
                        </div>
                        <div className="section-content flex items-center w-full pb-20 pt-6">
                            <div className="grid md:grid-cols-5 gap-2 w-full">
                                <div className="hidden md:block flex flex-col justify-center align-center w-full px-4 pb-4">
                                    <div className="flex items-center justify-center w-full h-64 py-5">
                                        <img src="/images/rocket.svg" className="h-full animate-6" />
                                    </div>
                                    <div className="text-gray-500 font-semibold flex flex-col !text-sm">
                                        <div className="flex gap-3 items-center h-12">
                                            <SmartToyTwoToneIcon className="text-gray-400 !text-lg" />
                                            <span>Chatbros</span>
                                        </div>
                                        <div className="flex gap-3 items-center h-12">
                                            <ChatTwoTone className="text-gray-400 !text-lg" />
                                            <span>Conversations / month</span>
                                        </div>
                                        <div className="flex gap-3 items-center h-12">
                                            <SchoolTwoTone className="text-gray-400 !text-lg" />
                                            <span>Data Sources</span>
                                        </div>
                                        <div className="flex gap-3 items-center h-12">
                                            <PublicTwoTone className="text-gray-400 !text-lg" />
                                            <span>Embed on websites</span>
                                        </div>
                                        <div className="flex gap-3 items-center h-12">
                                            <QuestionAnswerTwoTone className="text-gray-400 !text-lg" />
                                            <span>Live Chat</span>
                                        </div>
                                        <div className="flex gap-3 items-center h-12">
                                            <SupportAgentTwoTone className="text-gray-400 !text-lg" />
                                            <span>24/7 Support</span>
                                        </div>
                                        <div className="flex gap-3 items-center h-12">
                                            <BrandingWatermarkTwoTone className="text-gray-400 !text-lg" />
                                            <span>Removed Watermark</span>
                                        </div>
                                    </div>
                                </div>
                                {pricingTable.map((item, key) => (
                                    <div className={`flex flex-col justify-center align-center w-full px-2 pb-4 rounded-xl md:rounded-none !bg-opacity-10 bg-blue-400 md:bg-transparent ${item.popular && `!bg-purple-400 !rounded-xl`}`}>
                                        <div className="flex flex-col gap-4 items-center justify-center w-full h-64 py-5">
                                            <span className="p-2 px-3 rounded-full uppercase bg-gray-200 text-xs font-semibold text-gray-700">{item.name}</span>
                                            <h2 className="text-5xl font-bold text-gray-800">${item.price?.[checked ? 'year' : 'month'].toLocaleString(undefined, { maximumFractionDigits: 0 })}<small className="text-base !text-gray-700">/mo</small></h2>
                                            <hr className="!border-gray-400 w-full mt-1.5" />
                                            <p className="text-center text-sm">{item.description}</p>
                                        </div>
                                        <div className="text-gray-500 font-semibold flex flex-col !text-sm">
                                            <div className="flex gap-3 items-center justify-center h-8 md:h-12 text-gray-700 text-center">
                                                <div className="items-center inline-flex gap-2">
                                                    <SmartToyTwoToneIcon className="md:!hidden text-gray-400 !text-lg" />
                                                    <span>{item.chatbros}</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 items-center justify-center h-8 md:h-12 text-gray-700 text-center">
                                                <div className="items-center inline-flex gap-2">
                                                    <ChatTwoTone className="md:!hidden text-gray-400 !text-lg" />
                                                    <span>{item.conversations}</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 items-center justify-center h-8 md:h-12 text-gray-700 text-center">
                                                <div className="items-center inline-flex gap-2">
                                                    <TravelExploreTwoTone className="md:!hidden text-gray-400 !text-lg" />
                                                    <span>{item.web_pages}</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-3 items-center justify-center h-8 md:h-12 text-gray-700 text-center">
                                                <div className="md:!hidden items-center inline-flex gap-2">
                                                    <PublicTwoTone className="text-gray-400 !text-lg" />
                                                    <span>Embed on websites</span>
                                                </div>
                                                <CheckTwoTone className="!hidden md:!inline-block text-green-600 !text-lg" />
                                            </div>
                                            <div className="flex gap-3 items-center justify-center h-8 md:h-12 text-gray-700 text-center">
                                                <div className="md:!hidden items-center inline-flex gap-2">
                                                    <QuestionAnswerTwoTone className="text-gray-400 !text-lg" />
                                                    <span>Live Chat</span>
                                                </div>
                                                <CheckTwoTone className="!hidden md:!inline-block text-green-600 !text-lg" />
                                            </div>
                                            <div className="flex gap-3 items-center justify-center h-8 md:h-12 text-gray-700 text-center">
                                                <div className="md:!hidden items-center inline-flex gap-2">
                                                    <SupportAgentTwoTone className="text-gray-400 !text-lg" />
                                                    <span>24/7 Support</span>
                                                </div>
                                                <CheckTwoTone className="!hidden md:!inline-block text-green-600 !text-lg" />
                                            </div>
                                            <div className="flex gap-2 items-center justify-center h-8 md:h-12 text-gray-700 text-center">
                                                <div className="md:!hidden items-center inline-flex gap-2">
                                                    <BrandingWatermarkTwoTone className="text-gray-400 !text-lg" />
                                                    <span>Removed Watermark</span>
                                                </div>
                                                {item.watermark 
                                                    ? <CheckTwoTone className="text-green-600 !text-lg" />
                                                    : <CloseTwoTone className="text-red-600 !text-lg" />
                                                }
                                            </div>
                                            <div className="flex gap-3 items-end justify-center h-12 md:h-12 text-gray-700 text-center">
                                                <a href="https://app.chatbros.ai" className="underline text-primary">Create an account</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing;