import { useNavigate } from "react-router-dom";

const BlogItem = (props) => {

    const {
        previewImage,
        title,
        description,
        sys,
        slug
    } = props;

    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(`/blog/${slug}`)} className="overflow-hidden cursor-pointer group rounded-lg transition-all hover:translate-y-[-4px]" style={{ boxShadow: "0 20px 55px rgba(82,71,175,.2)" }}>
            <div className="relative overflow-hidden">
                <img src={previewImage?.url} className="w-full h-56 object-cover group-hover:scale-[1.03] transition-all" />
            </div>
            <div className="p-5 py-4 bg-gray-50 flex flex-col gap-3 text-sm">
                <div className="flex items-center gap-3">
                    <span className="text-gray-800 font-semibold">Chatbros</span>
                    <span className="text-[10px]">&#9679;</span>
                    <span className="text-gray-500 font-[500]">{new Date(sys?.publishedAt)?.toDateString()}</span>
                </div>
                <h2 className="text-2xl text-gray-700 !m-0 font-semibold leading-[27px] line-clamp-2 overflow-hidden">{title}</h2>
                <p className="line-clamp-3 text-sm text-[#737588]">{description}</p>
            </div>
        </div>
    )
}

export default BlogItem;