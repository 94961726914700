import React, { useState } from "react";

const Form = () => {

    const [ name, setName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ howToPromote, setHowToPromote ] = useState("");
    const [ isLoadingApply, setIsLoadingApply ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ successMessage, setSuccessMessage ] = useState("");

    const encode = (data) => {
        return Object.keys(data).map(
            (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
        ).join('&');
    };
    
    const handleClickApply = () => {
        setErrorMessage("");
        setSuccessMessage("");
        if (!email || !name || !howToPromote) {
            setErrorMessage("Please complete all required fields.");
            return;
        }
        setIsLoadingApply(true);
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 
                'form-name': "affiliate", 
                'name': name,
                'email': email,
                'howToPromote': howToPromote
            }),
        });
        setTimeout(() => {
            setName("");
            setEmail("");
            setHowToPromote("");
            setSuccessMessage("Thank you! We will be in touch as soon as possible!");
            setIsLoadingApply(false);
        }, 900);
    }

    return (
        <section className="section bg-light !mt-0 !bg-transparent min-h-full flex items-center justify-center">
            <div className="overflow-hidden max-w-[964px] w-full rounded-2xl bg-gray-100 px-0 md:px-6 p-6 pb-8 flex flex-col items-center justify-center gap-6 relative">
                <span className="rounded-full p-2 px-4 uppercase bg-gray-200 text-blue-400 font-semibold text-sm tracking-[1.5px]">START EARNING TODAY</span>
                <h1 className="title h1 -mt-10 tracking-[-2px] md:tracking-inherit">Apply Now!</h1>
                <div className="bg-white w-full md:w-[90%] max-w-[650px] rounded-xl p-4 md:p-6 z-[2] relative">
                    <div className="flex flex-col gap-4">
                        <input 
                            className="!h-auto px-2 py-3 text-gray-700 border-0 !border-solid !border-b-[2px] !border-gray-300 !outline-0 w-full"
                            placeholder="Full Name"
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                        <input 
                            className="!h-auto px-2 py-3 text-gray-700 border-0 !border-solid !border-b-[2px] !border-gray-300 !outline-0 w-full"
                            placeholder="Email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <textarea 
                            className="col-span-2 !h-auto min-h-[150px] px-2 py-3 text-gray-700 border-0 !border-solid !border-b-[2px] !border-gray-300 !outline-0 w-full"
                            placeholder="How Will You Promote Chatbros?"
                            name="how_to_promote"
                            onChange={(e) => setHowToPromote(e.target.value)}
                            value={howToPromote}
                        />
                        <button onClick={handleClickApply} disabled={isLoadingApply} className={`col-span-2 mt-2 !w-full bg-green-300 text-center text-gray-700 rounded-full py-3 font-semibold ${isLoadingApply && `opacity-[0.3]`}`}>
                            {isLoadingApply 
                                ? `Loading...`
                                : `Apply Now!`
                            }
                        </button>
                        {errorMessage && <p className="text-red-600 font-semibold mt-2 text-center">
                            {errorMessage}
                        </p>}
                        {successMessage && <p className="text-green-600 font-semibold mt-2 text-center">
                            {successMessage}
                        </p>}
                    </div>
                </div>

                <img src="/images/3d-character-with-phone.png" className="w-20 md:w-28 absolute left-6 md:left-16 top-16 md:top-20 animate-6 z-[2] pointer-events-none" />
                <img src="/images/chat-mate.png" className="w-24 md:w-36 absolute right-4 md:right-16 bottom-10 md:bottom-20 animate-7 z-[2] pointer-events-none" />
                <img src="/images/shape-2.svg" className="w-[80%] md:w-[30%] absolute z-[0] -right-10 top-1/2 -translate-y-1/2 pointer-events-none" />
            </div>
        </section>
    );
}

export default Form;