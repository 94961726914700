import React, { useEffect, useState } from "react";
import { BookTwoTone, QuestionAnswerTwoTone } from "@mui/icons-material";
import BlogItem from "./BlogItem";

const Blog = ({
    isHome = false
}) => {

    const [ blogs, setBlogs ] = useState();

    const collection = `blogCollection`;
    const queryBlogs = `
        query {
            ${collection} {
                items {
                    title
                    description
                    keywords
                    slug
                    sys {
                      publishedAt
                    }
                    previewImage {
                        url
                    }
                    content {
                        json
                    }
                }
            }
        }
    `;

    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/aov9i9sivcfe/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer KrDZdPY-NQiE_mxMlnmioRCZo5nbMh950f3b-D9tgbM",
            },
            body: JSON.stringify({ query: queryBlogs }),
        }).then((response) => {
            return response.json()
        }).then(({ data, errors }) => {
            if (!errors) setBlogs(data?.[collection]?.items);
        }).catch(() => {
        }).finally(() => {
        });
    }, []);

    if (isHome && !blogs?.length) return <></>;

    return (
        <section className="section section-bottom-0 relative" id="blog">
            <div className="container">
                <div className="flex flex-col lg:flex-row items-start justify-between space-y-10 lg:space-y-0">
                    <div className="w-full">
                        <div className="flex w-full items-center justify-center mb-6">
                            <div className="h-20 w-20 text-4xl hidden lg:flex items-center justify-center rounded-full overflow-hidden relative">
                                <div className="absolute left-0 top-0 w-full h-full bg-green-600 opacity-[0.3]"></div>
                                <BookTwoTone className="text-green-600 !text-4xl" />
                            </div>
                        </div>
                        <div className="section-head !flex flex-col gap-7 md:gap-0 md:flex-row justify-between w-full mb-0 pb-0">
                            <div className="flex flex-col lg:flex-row justify-between gap-5 items-center flex-1">
                                <div className="flex-1 px-2 lg:px-0">
                                    <span className="p-2 px-3 rounded-full uppercase bg-gray-200 text-xs font-semibold text-gray-700 mb-2.5 inline-block">GET TO KNOW OUR STORY</span>
                                    <h1 className="title h1 !text-4xl sm:!text-5xl tracking-[-1px] !text-gray-700 !-mt-1 text-left">Latest Posts</h1>
                                </div>
                                <p className="text-lg -mt-3 text-center lg:!text-left max-w-2xl m-auto">Welcome to the ChatBros blog, your go-to destination for insightful articles, engaging discussions, and the latest updates on AI-powered chatbot technology and its endless possibilities.</p>
                            </div>
                        </div>
                        {!blogs?.length && 
                            <div className="font-semibold text-2xl text-center w-full py-20">There are not blog posts yet. Come again another time!</div>
                        }
                        {!!blogs?.length && 
                            <div className="section-content grid flex flex-col gap-6 lg:gap-12 lg:grid lg:grid-cols-3 w-full pb-20 pt-6">
                                {blogs?.map((item) => (
                                    <BlogItem {...item} />
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blog;