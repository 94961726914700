import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import Home from "./home";
import Privacy from "./privacy";
import Terms from "./terms";
import Pricing from "./home/Pricing";
import Affiliates from "./affiliate";
import Demo from "./demo";
import Blog from "./home/Blog";
import BlogPage from "./blog/BlogPage";

const RoutesProvider = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home/>} />
            <Route path="/affiliates" element={<Affiliates />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPage />} />
        </Routes>
    )
}

export default RoutesProvider;