import React from "react";
import { TypeAnimation } from "react-type-animation";

const HeroSection = () => {
    return (
        <div className="nk-hero py-xl-5 overflow-hidden has-shape !min-h-[100%] sm:!min-h-[100vh] w-full flex items-center justify-center -mt-5 lg:!-mt-16">
            {/* <div className="nk-shape bg-shape-blur-b mt-n5 start-50 top-50 translate-middle"></div> */}
                <div className="container">
                    <div className="row justify-content-center text-center">
                    <div className="col-lg-11 col-xl-10 col-xxl-9">
                            <div className="nk-hero-content py-5 py-lg-6">
                                <h1 className="title mb-5 sm:mb-4 text-gradient-primary !text-6xl sm:!text-7xl">
                                    One Chatbot<br className="hidden sm:inline-block" /> To Replace Them All
                                </h1>
                                <p className="lead px-md-8 px-lg-6 px-xxl-12 mb-4 mb-lg-5 !text-base sm:!text-lg px-2">Step into a new era of digital interaction with ChatBros.ai – the AI-powered assistant that reshapes conversions, fuels sales growth, and completely transforms user engagement. Seamless assistance with limitless capabilities.</p>
                                <ul className="btn-list btn-list-inline mt-5 sm:!mt-12">
                                    <li><a href="https://app.chatbros.ai" className="btn btn-primary btn-xl rounded-pill !font-bold !text-sm"><span>Get Started Now!</span></a></li>
                                </ul>
                                <p className="sub-text mt-2"><a href="/demo" className="underline">Try our demo for free</a></p>
                            </div>
                            <div className="nk-hero-gfx position-relative">
                                {/* <img className="w-100 rounded-4" src="images/gfx/banner/c.jpg" alt="" /> */}
                                <div className="animate-1 d-none d-md-block position-absolute top-0 end-100 me-5 me-lg-8 me-xl-12 mt-n3 !-mt-96 !mr-24">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/3d-character-with-phone.png" className="!w-auto h-64 max-w-none drop-shadow-xl" alt="" />
                                    </div>
                                </div>
                                <div className="animate-1 d-none d-md-block position-absolute top-0 end-100 me-5 me-lg-8 me-xl-12 mt-n3 !-mt-0 !-mr-44">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/cloud.png" className="!w-auto h-20 max-w-none drop-shadow-xl" alt="" />
                                    </div>
                                </div>
                                <div className="animate-2 d-none d-md-block position-absolute top-0 start-100 ms-5 ms-lg-7 ms-xl-10 mt-n7 !-mt-72 !-ml-0">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/message-bubble.png" alt="" className="!w-auto h-20 max-w-none drop-shadow-xl" />
                                    </div>
                                </div>
                                <div className="animate-4 d-none d-md-block position-absolute top-50 start-100 ms-4 ms-lg-5 !-mt-10 !-ml-44">
                                    <div className="media media-2xl rounded-xl mx-auto">
                                        <img src="images/hand.png" alt="" className="!w-auto h-32 max-w-none drop-shadow-xl" />
                                    </div>
                                </div>
                            </div>
                            <div className="nk-hero-content pb-6 pt-2 lg:pt-6">
                                    <h6 className="lead-text !text-xs sm:!text-sm hidden sm:block">Trusted by 2,000+ companies around the globe.</h6>
                                    <a href="https://followr.ai?utm_campaign=chatbros&utm_medium=referral&utm_source=chatbros" target="_blank">
                                        <div className="flex mt-4 gap-2 items-center justify-center w-full">
                                            <small>Powered by</small>
                                            <img src="images/followr.png" className="h-8 sm:h-10 invert opacity-[0.4]" alt="" />
                                        </div>
                                    </a>
                                    {/* <ul className="d-flex flex-wrap justify-content-center pt-4 has-gap gy-3">
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-b-white.png" alt="" />
                                            </li>
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-c-white.png" alt="" />
                                            </li>
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-d-white.png" alt="" />
                                            </li>
                                            <li className="px-3 px-sm-5">
                                                    <img className="h-2rem" src="images/brands/72-e-white.png" alt="" />
                                            </li>
                                    </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default HeroSection;