import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const AffiliateBenefits = () => {

    return (
        <div className="relative py-6 px-4 lg:!py-12 flex flex-col lg:flex-row items-center max-w-[1100px] gap-6 lg:gap-8 mx-auto">
            <img src="/images/affiliate-2.png" className="shrink-0 w-full max-w-[350px] animate-5" />
            <div className="flex flex-col gap-5 text-center lg:!text-left">
                <h2 className="font-bold text-5xl text-third">ChatBros Affiliate Marketing Program</h2>
                <p className="text-lg">Join ChatBros' Affiliate Marketing Program today and <b>start earning a recurring 25% commission for life</b>! With our extended 360-day referral cookie life, your earnings potential is limitless.</p>
                {/* <Link to={"#"} className="text-lg text-gradient-primary !inline-flex items-center gap-1.5 mx-auto lg:!mx-0"><span>Learn More</span><HiChevronRight className="mt-0.5 text-primary" /></Link> */}
            </div>
        </div>
    )
}

export default AffiliateBenefits; 