import React, { useEffect } from "react";
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import { useLocation } from 'react-router-dom';
import OfferBanner from "../components/OfferBanner";

const Layout = ({ children }) => {

    const location = useLocation();

    return (
        <div className="nk-app-root bg-[#323945]">
            <OfferBanner />
            <main className="nk-pages relative overflow-hidden rounded-b-3xl rounded-t-3xl bg-white">
                <Navbar showHero={location.pathname == "/home"} />
                {children}
            </main>
            <Footer />
        </div>
    )
}

export default Layout;