import React, { useState } from "react";
import SinglePricingCard from "../../components/SinglePricingCard";
import { AiFillCreditCard } from "react-icons/ai";
import { AddTwoTone, AutoAwesomeTwoTone } from "@mui/icons-material";
import BenefitsItem from "./BenefitsItem";

export const benefits = [
    {
        title: "Simplify Interaction",
        description: "Effortlessly streamline customer communication and support using our intuitive chatbot interface, elevating the user experience by integrating our AI-driven chatbot."
    },
    {
        title: "Limitlessly Customizable",
        description: "Shape ChatBros.ai's features to impeccably align with your brand and seamlessly blend with your website aesthetics, while enhancing the user experience with quick buttons that offer a more engaging and efficient interaction."
    },
    {
        title: "Warm Welcome Message",
        description: "Craft a captivating welcome message that embodies your brand's personality, instantly connecting with users, and enabling smooth communication through contact form integration, empowering users to connect effortlessly."
    },
    {
        title: "Expanded Knowledge Sources",
        description: "Empower AI learning by providing diverse resources, allowing ChatBros.ai to deliver more insightful responses, while personalizing your AI assistant's appearance with a customized avatar and color that resonates seamlessly with your brand identity."
    },
]

const BenefitsExpanded = () => {

    const [ isOpen, setIsOpen ] = useState(0);

    return (
        <section className="section section-bottom-0 bg-light !bg-transparent !mt-6 lg:!mt-5 min-h-full">
            <div className="container">
                <div className="flex flex-col lg:flex-row items-end justify-between space-y-10 lg:space-y-0">
                    <div className="flex-1 pb-8">
                        <div className="section-head">
                            <div className="flex gap-5 items-center">
                                <div className="h-20 w-20 text-3xl hidden lg:flex items-center justify-center rounded-full overflow-hidden relative">
                                    <div className="absolute left-0 top-0 w-full h-full bg-info opacity-[0.3]"></div>
                                    <AutoAwesomeTwoTone className="text-info !text-3xl" />
                                </div>
                                <div className="px-2 lg:px-0">
                                    <h1 className="title h1 !text-4xl sm:!text-3xl tracking-[-1px] !text-gray-800 !-mt-1">Seamless Assistance, Infinite Capabilities</h1>
                                    <p className="text-lg -mt-3">Unleash an unmatched era of customer engagement.</p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content flex flex-col items-center divide-x-10 max-w-[600px] w-full">
                            {benefits.map((item, key) => (
                                <BenefitsItem 
                                    key={key} 
                                    index={key}
                                    title={item.title}
                                    description={item.description}
                                    isOpen={isOpen == key}
                                    setIsOpen={setIsOpen}
                                />
                            ))}
                        </div>
                    </div>
                    <img src="/images/hand-phone-emojis.png" className="w-[90%] max-w-[500px] lg:w-[425px] mx-auto lg:!mx-0" />
                </div>
            </div>
        </section>
    )
}

export default BenefitsExpanded;