import React, { useState } from "react";
import { QuestionAnswerTwoTone } from "@mui/icons-material";
import FAQItem from "./FAQItem";

export const faqs = [
    {
        question: "Is there a Free Plan?",
        answer: "No, we do not offer a free plan."
    },
    {
        question: "Are there message limits?",
        answer: "Certainly, each plan has specific message limits. For detailed limits, please refer to our pricing plans above."
    },
    {
        question: "What type of content is suitable for chatbot training?",
        answer: "You can employ various content types for chatbot training. The more diverse your content, the better the chatbot's responses."
    },
    {
        question: "What is a conversation?",
        answer: "A conversation is a user interacting with the chat within a 24-hour period, with no message limit. After 24 hours, even if it's the same user, it counts as another conversation."
    },
    {
        question: "How can I train the chatbot?",
        answer: "With the data-sources, training is simple. Just provide a website link, sitemap link, Help Center Link, Gitbook link, or blog link. The chatbot will learn from the content on the provided URL."
    },
    {
        question: "How can I integrate the chatbot into my website?",
        answer: "Each chatbot is allocated a unique URL. Embed it on your site using the provided embed code. You can also link directly to the chatbot from your website."
    },
    {
        question: "Can I access chat interactions?",
        answer: "Absolutely, you have full access to chat interactions. You can review the entire chat history. Additionally, we'll soon provide automatic insights based on chatbot history, such as frequently asked questions."
    },
    {
        question: "What are \"data-sources\"?",
        answer: "\"Data-sources\" refer to any links provided to the chatbot for acquiring knowledge about your company or business. These links can include your blog, help center, articles from media, website, newsletters, reviews, and more. The number of links the bot can use to learn varies depending on the plan you choose."
    },
    {
        question: "Can I customize the chatbot's appearance and behavior?",
        answer: "Yes, you have extensive customization options. Modify the chatbot's color, icon, name, suggestions, and more."
    },
    {
        question: "Can I remove the watermark?",
        answer: "Yes, you can remove the ChatBros logo watermark starting from the Business plan, making it ideal for larger enterprises."
    },
    {
        question: "Can I embed on unlimited websites?",
        answer: "Yes, you can! With ChatBros, a single chatbot can be embedded on multiple websites simultaneously. Plus, as you upgrade your plan, you'll have access to more data sources to enhance your bot's knowledge and effectiveness, along with an increased number of conversations."
    },
    {
        question: "Do you store chat messages?",
        answer: "Yes, we store all visitor questions and chatbot responses, making them accessible to you via the dashboard."
    },
    {
        question: "Does it support languages other than English?",
        answer: "Certainly, our chatbot is language-agnostic. It can handle websites in any language and respond in multiple languages. Feel free to interact with it in your preferred language."
    },
];


const FAQ = () => {

    return (
        <section className="section section-bottom-0 relative" id="faq">
            <div className="container">
                <div className="flex flex-col lg:flex-row items-start justify-between space-y-10 lg:space-y-0">
                    <div className="w-full">
                        <div className="section-head !flex flex-col gap-7 md:gap-0 md:flex-row justify-between w-full mb-0 pb-0">
                            <div className="flex flex-col justify-center gap-5 items-center flex-1">
                                <div className="h-16 w-16 text-4xl hidden lg:flex items-center justify-center rounded-full overflow-hidden relative">
                                    <div className="absolute left-0 top-0 w-full h-full bg-green-600 opacity-[0.3]"></div>
                                    <QuestionAnswerTwoTone className="text-green-600 !text-3xl" />
                                </div>
                                <div className="px-2 lg:px-0 flex flex-col items-center justify-center gap-4">
                                    <h1 className="title h1 !text-4xl sm:!text-4xl tracking-[-1px] !text-gray-800 !-mt-1 text-center">Frecuently Asked Questions</h1>
                                    <p className="text-base -mt-3 text-center max-w-2xl m-auto">Have a different question and can’t find the answer you’re looking for? Reach out to our support team by <a href="mailto:hello@chatbros.ai" className="text-primary">sending us an email</a> and we'll get back to you as soon as we can.</p>
                                </div>
                            </div>
                        </div>
                        <div className="section-content flex items-center w-full pb-20 pt-6">
                            <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-3 md:px-4">
                                <div className="flex flex-col gap-3">
                                    {faqs.slice(0, Math.ceil(faqs.length / 2)).map((faq, key) => (
                                        <FAQItem key={key} {...faq} />
                                    ))}
                                </div>
                                <div className="flex flex-col gap-3">
                                    {faqs.slice(Math.ceil(faqs.length / 2), faqs.length).map((faq, key) => (
                                        <FAQItem key={key} {...faq} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FAQ;