import React from "react";
import { HiChevronRight } from "react-icons/hi"

const Benefits = () => {

    return (
        <div className="mt-6 pb-20 relative" style={{ backgroundImage: "linear-gradient(180deg, #DBE4FE 0%, #fff 100%)" }}>
            <svg className="h-44 w-[300%] absolute left-0 top-[-1px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#fff"><path class="lqd-shape-fill" d="M738,99l262-93V0H0v5.6L738,99z"></path></svg>

            <div className="grid lg:grid-cols-2 gap-6 max-w-[1100px] w-full mx-auto z-[10] relative">
                <div className="cursor-pointer transition-all duration-500 group bg-white hover:!bg-[#0e3f58] rounded-2xl p-6 py-5 shadow-sm flex items-start gap-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 w-8 mt-2 group-hover:grayscale-[1] group-hover:brightness-[2]" viewBox="0 0 27.388 40.486"><g transform="translate(-16.075 -10.122)"><path d="M4.763,10.717c2.484,0,4.763-6.974,4.763-8.335C9.526,1.08,7.512,0,4.763,0,2.543,0,0,1.08,0,2.382,0,3.743,2.279,10.717,4.763,10.717Z" transform="translate(25.006 39.891)" fill="rgba(187,109,229,0.14)"></path><path d="M26.319,16.056l-4.851-.6a3.1,3.1,0,0,1-2.654-2.992V1.271c0-1.475.706-1.7,1.578-.506L25.81,8.172a9.534,9.534,0,0,1,1.578,4.82v2.134a.917.917,0,0,1-.935.937A1.078,1.078,0,0,1,26.319,16.056ZM0,15.114v-2.1A9.591,9.591,0,0,1,1.582,8.165L6.991.772C7.865-.423,8.574-.19,8.574,1.29V12.442a3.112,3.112,0,0,1-2.654,3.01l-4.851.6a1.076,1.076,0,0,1-.133.008A.921.921,0,0,1,0,15.114Z" transform="translate(16.075 28.234)" fill="rgb(187,109,229)"></path><path d="M.3,9.989a12.916,12.916,0,0,1,5.577-8.9A5.223,5.223,0,0,1,8.336,0a5.223,5.223,0,0,1,2.458,1.091A12.958,12.958,0,0,1,16.374,10s.405,1.709.27,9.372a106.118,106.118,0,0,1-1.112,13.661,1.217,1.217,0,0,1-1.718.992,12.129,12.129,0,0,0-5.479-.964,12.129,12.129,0,0,0-5.479.964A1.21,1.21,0,0,1,1.139,33.02,107.386,107.386,0,0,1,.027,19.372C-.107,11.791.3,9.989.3,9.989Z" transform="translate(21.433 10.122)" fill="rgb(187,109,229)" opacity="0.14"></path><path d="M2.857,25.826V14.675a1.143,1.143,0,1,1,2.285,0V25.826a1.143,1.143,0,1,1-2.285,0ZM0,4.109A4.055,4.055,0,0,1,4,0,4.056,4.056,0,0,1,8,4.109,4.056,4.056,0,0,1,4,8.218,4.056,4.056,0,0,1,0,4.109Z" transform="translate(26 17.538)" fill="rgb(187,109,229)"></path></g></svg>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-third font-[700] text-xl group-hover:!text-white flex items-center gap-2"><span>Boost Conversions</span><HiChevronRight className="hidden text-white group-hover:inline-block mt-1" /></h2>
                        <p className="text-base group-hover:!text-white">Engage, convert, and drive sales with Chatbros.ai's intelligent chatbot.</p>
                    </div>
                </div>
                <div className="cursor-pointer transition-all duration-500 group bg-white hover:!bg-[#0e3f58] rounded-2xl p-6 py-5 shadow-sm flex items-start gap-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 w-8 mt-2 group-hover:grayscale-[1] group-hover:brightness-[2]" viewBox="0 0 30.783 35.044"><path d="m1.874 18.985a1.876 1.876 0 0 1 0-3.752h3.755a1.876 1.876 0 0 1 0 3.752zm3.754-8.6-2.655-2.656a1.876 1.876 0 0 1 2.653-2.653l2.655 2.656a1.876 1.876 0 0 1 -2.653 2.653zm16.1 0a1.875 1.875 0 0 1 0-2.652l2.655-2.655a1.876 1.876 0 0 1 2.653 2.653l-2.655 2.655a1.876 1.876 0 0 1 -2.652 0zm-8.828-4.756v-3.755a1.876 1.876 0 0 1 3.752 0v3.755a1.876 1.876 0 0 1 -3.752 0z" fill="rgba(187,109,229,0.4)"></path><path d="m11.746 13.271 6.112-2.9c.62-.295.627-.794.024-1.121l-16.869-9.116c-.603-.326-1.056-.034-1.013.647l1.272 18.831c.046.69.513.89 1.042.461l5.264-4.264 4.459 7.135a1.244 1.244 0 0 0 1.712.392l2.028-1.267a1.244 1.244 0 0 0 .4-1.711z" fill="rgb(187,109,229)" transform="translate(12.454 11.519)"></path></svg>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-third font-[700] text-xl group-hover:!text-white flex items-center gap-2"><span>Simplify Interaction</span><HiChevronRight className="hidden text-white group-hover:inline-block mt-1" /></h2>
                        <p className="text-base group-hover:!text-white">Simplify customer communication and support with our user-friendly chatbot</p>
                    </div>
                </div>
                <div className="cursor-pointer transition-all duration-500 group bg-white hover:!bg-[#0e3f58] rounded-2xl p-6 py-5 shadow-sm flex items-start gap-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 w-8 mt-2 group-hover:grayscale-[1] group-hover:brightness-[2]" viewBox="0 0 34 34.028"><rect width="34" height="34.003" transform="translate(0 0.025)" fill="none"></rect><path d="M28.333,0V5.668A2.834,2.834,0,0,1,25.5,8.5H2.833A2.834,2.834,0,0,1,0,5.668V0H6.062l.771,2.313A2.833,2.833,0,0,0,9.521,4.251h9.437a2.833,2.833,0,0,0,2.534-1.567L22.834,0Z" transform="translate(2.833 24.108)" fill="rgb(187,109,229)"></path><path d="M0,9.919,4.766,1.445A2.833,2.833,0,0,1,7.235,0H19.5a2.833,2.833,0,0,1,2.469,1.445L26.74,9.919h-4.7A2.833,2.833,0,0,0,19.5,11.486L18.161,14.17H8.724l-.771-2.313A2.833,2.833,0,0,0,5.265,9.919Z" transform="translate(3.63 11.355)" fill="rgb(187,109,229)" opacity="0.3"></path><path d="M4.72.169A.709.709,0,0,0,3.553.71V3.481H.708A.708.708,0,0,0,0,4.19v1.45a.708.708,0,0,0, .708.708H3.553V9.119A.709.709,0,0,0,4.72,9.66l4.968-4.2a.708.708,0,0,0,0-1.081Z" transform="translate(12.085 9.938) rotate(-90)" fill="rgb(187,109,229)"></path></svg>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-third font-[700] text-xl group-hover:!text-white flex items-center gap-2"><span>Enhance User Experience</span><HiChevronRight className="hidden text-white group-hover:inline-block mt-1" /></h2>
                        <p className="text-base group-hover:!text-white">Elevate website engagement and satisfaction with our AI-powered chatbot.</p>
                    </div>
                </div>
                <div className="cursor-pointer transition-all duration-500 group bg-white hover:!bg-[#0e3f58] rounded-2xl p-6 py-5 shadow-sm flex items-start gap-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 w-8 mt-2 group-hover:grayscale-[1] group-hover:brightness-[2]" viewBox="0 0 35.35 34.872"><g fill="rgb(187,109,229)"><path d="m16.169 18.979-9.511-9.491-3.644-.793-3.014-5.852 2.849-2.843 5.864 3.008.795 3.636 9.511 9.492.486-.485a2.679 2.679 0 0 1 3.779 0l8.582 8.564a2.667 2.667 0 0 1 0 3.772l-3.819 3.813a2.679 2.679 0 0 1 -3.779 0l-8.582-8.564a2.667 2.667 0 0 1 0-3.772l.486-.485z" opacity=".191" transform="translate(0 2.29)"></path><path d="m14.8 14.256a10.021 10.021 0 0 1 13.311-13.325l-5.575 4.92a3.826 3.826 0 0 0 -1.1 3.276l.29 1.454a2.142 2.142 0 0 0 1.562 1.559l1.457.29a3.845 3.845 0 0 0 3.283-1.1l4.93-5.564a10.021 10.021 0 0 1 -13.358 13.279 3.946 3.946 0 0 1 -.312.371l-12.575 13.213a3.788 3.788 0 0 1 -5.444.073l-.149-.149a3.771 3.771 0 0 1 .073-5.433l13.239-12.553a3.947 3.947 0 0 1 .368-.311z" transform="translate(1.464)"></path></g></svg>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-third font-[700] text-xl group-hover:!text-white flex items-center gap-2"><span>Fully Customizable</span><HiChevronRight className="hidden text-white group-hover:inline-block mt-1" /></h2>
                        <p className="text-base group-hover:!text-white">Tailor Chatbros.ai's chatbot to perfectly match your brand and website aesthetics.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Benefits;