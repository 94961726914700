import React from "react";
import Benefits from "./Benefits";
import Pricing from "./Pricing";
import BigTextSection from "./BigTextSection";
import WhatIsChatbro from "./WhatIsChatbro";
import FAQ from "./FAQ";
import BenefitsExpanded from "./BenefitsExpanded";
import DemoSection from "../demo/DemoSection";
import Blog from "./Blog";

const Home = () => {

    return (
        <>
            <WhatIsChatbro />
            <BigTextSection />
            <Benefits />
            <DemoSection />
            <BenefitsExpanded />
            <Pricing />
            <Blog isHome={true} />
            <FAQ />
        </>
    );
}

export default Home;