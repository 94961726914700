import React from "react";
import HeroSection from "../../views/home/HeroSection";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ showHero = false }) => {

    const location = useLocation();

	return (
		<header id="header" className="nk-header has-mask top-0 left-0 w-full z-[10000] bg-lucecitas">
			<div className="nk-mask bg-pattern-dot-white-sm bg-blend-bottom"></div>
			<div className="nk-header-main nk-menu-main is-transparent will-shrink ignore-mask">
				<div className="container">
					<div className="nk-header-wrap flex !justify-between items-center gap-6">
						<div className="nk-header-logo">
							<a href="#" className="logo-link">
								<div className="flex items-center gap-2.5">
									<img className="h-8" src="images/logo.png" alt="" />
									<h1 className="text-2xl !tracking-[0px] text-gradient-primary">Chatbros</h1>
								</div>
							</a>
						</div>
						<div className="nk-header-toggle">
							<button className="btn btn-light btn-icon header-menu-toggle">
								<em className="icon ni ni-menu"></em>
							</button>
						</div>
						<nav className="nk-header-menu nk-menu">
							<ul className="nk-menu-list !ml-auto gap-3">
								<li className={`nk-menu-item ${location.pathname == "/home" && `active current-page`}`}>
									<a href={"/home#"} className="nk-menu-link">
										<span className="nk-menu-text !text-sm">Home</span>
									</a>
								</li>
								<li className={`nk-menu-item ${location.pathname == "/pricing" && `active current-page`}`}>
									<a href={"/home#pricing"} className="nk-menu-link">
										<span className="nk-menu-text !text-sm">Pricing</span>
									</a>
								</li>
								<li className={`nk-menu-item ${location.pathname == "/demo" && `active current-page`}`}>
									<a href={"/demo"} className="nk-menu-link">
										<span className="nk-menu-text !text-sm">Demo</span>
									</a>
								</li>
								<li className={`nk-menu-item ${location.pathname.includes("/blog") && `active current-page`}`}>
									<a href={"/blog"} className="nk-menu-link">
										<span className="nk-menu-text !text-sm">Blog</span>
									</a>
								</li>
								<li className={`nk-menu-item ${location.pathname == "/affiliates" && `active current-page`}`}>
									<a href={"/affiliates#"} className="nk-menu-link">
										<span className="nk-menu-text !text-sm">Affiliates</span>
									</a>
								</li>
								<li className={`nk-menu-item ${location.pathname == "/billing" && `active current-page`}`}>
									<a href="https://billing.stripe.com/p/login/00gg1Wgwt4iy1OMaEE" target="_blank" className="nk-menu-link">
										<span className="nk-menu-text !text-sm">Billing</span>
									</a>
								</li>
							</ul>
							<ul className="nk-menu-buttons flex-lg-row-reverse">
								<li><a href="https://app.chatbros.ai" className="btn btn-primary rounded-pill !font-bold !text-sm py-2.5">Get Started Now!</a></li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
			
			{showHero && <HeroSection />}
		</header>
	);
}

export default Navbar;