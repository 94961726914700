import logo from './logo.svg';
import './App.css';
import Home from './views/home';
import Layout from './views/layout';
import RoutesProvider from './views/RoutesProvider';
import { BrowserRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import AnimatedCursor from "react-animated-cursor"

function App() {

  const cookies = new Cookies();
  if (!cookies.get("land_url")) {
    cookies.set('land_url', window.location.href, { path: '/', domain: "chatbros.ai" });
    cookies.set('land_url', window.location.href, { path: '/', domain: ".chatbros.ai" });
    cookies.set('document_referrer', document.referrer, { path: '/', domain: "chatbros.ai" });
    cookies.set('document_referrer', document.referrer, { path: '/', domain: ".chatbros.ai" });
  }

  return (
    <BrowserRouter>
      <Layout>
        <RoutesProvider />
        {/* <AnimatedCursor
          outerSize={38}
          outerScale={1.5}
          outerAlpha={0}
          hasBlendMode={true} 
          trailingSpeed={4}
          innerStyle={{
            backgroundColor: '#111'
          }}
          outerStyle={{
            border: '3px solid #111'
          }}
        /> */}
      </Layout>
    </BrowserRouter>
  );
}

export default App;
