import React from "react";

function OfferBanner() {

  return (
    <div className="flex justify-center items-center overflow-hidden w-full text-gray-50 gap-2 text-sm py-3 px-4 flex-wrap">
      <span className="hidden lg:!inline-block p-1 px-2 rounded text-white bg-primary mr-2 text-xs">Code: <b>EARLYBROS</b></span>
      <span>Sign up today and get a 20% lifetime discount.</span>
      <span className="inline-block lg:!hidden p-1 px-2 rounded text-white bg-primary mr-2 text-xs">Code: <b>EARLYBROS</b></span>
      <a href="https://app.chatbros.ai" className="underline">Join Chatbros</a>
    </div>
  );
}

export default OfferBanner;
