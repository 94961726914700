import { useState } from "react";


const DemoItem = ({
    index,
    icon,
    title,
    description,
    opened,
    setOpened,
}) => {

    return (
        <div onClick={() => setOpened(index)} className={`flex flex-col rounded-lg p-3 gap-2 cursor-pointer ${opened == index && `bg-gradient-to-b from-violet-200 to-transparent`}`}>
            <h2 className="font-bold text-secondary">{icon}&nbsp; {title}</h2>
            <p className={`transition-all text-sm ${opened != index && `!h-0 !scale-0 -mt-2`}`}>{description}</p>
        </div>
    )
}

export default DemoItem;