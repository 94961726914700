import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const WhatIsChatbro = () => {

    return (
        <div className="relative py-6 px-4 lg:!py-24 flex flex-col lg:flex-row items-center max-w-[1100px] gap-6 lg:gap-8 mx-auto">
            <img src="/images/man-in-bubble.png" className="shrink-0 w-full max-w-[425px] animate-5" />
            <div className="flex flex-col gap-5 text-center lg:!text-left">
                <h2 className="font-bold text-5xl text-third">Your Ultimate Virtual Employee</h2>
                <p className="text-base">Whether you aim to enhance conversions, guide your website visitors, or turbocharge your sales, ChatBros is your perfect partner. It dynamically learns about your company and executes tasks aligned with your objectives.</p>
                <Link to={"#"} className="text-lg text-gradient-primary !inline-flex items-center gap-1.5 mx-auto lg:!mx-0"><span>Learn More</span><HiChevronRight className="mt-0.5 text-primary" /></Link>
            </div>
        </div>
    )
}

export default WhatIsChatbro;