import React, { useState } from "react";
import SinglePricingCard from "../../components/SinglePricingCard";
import { AiFillCreditCard } from "react-icons/ai";
import { AccountBalanceWalletTwoTone, AddTwoTone, AutoAwesomeTwoTone, PaidTwoTone } from "@mui/icons-material";
import AffiliateStep from "./AffiliateStep";

export const benefits = [
    {
        title: "Step 1: Obtain Your Referral Link",
        description: "Get started by filling out the form below, and we'll promptly send you a unique referral link tailored just for you."
    },
    {
        title: "Step 2: Distribute Your Link to Your Audience",
        description: "Wondering where and how to promote? We provide you with marketing assets and recommendations to effectively share your referral link with your audience and gain exposure."
    },
    {
        title: "Step 3: Earn a Recurring 25% Commission for Life!",
        description: "ChatBros offers more than the rest. Enjoy the exclusive benefits of our affiliate program, including a user-friendly dashboard for tracking your earnings and referrals. Join us today."
    },
]

const Affiliate = () => {

    const [ isOpen, setIsOpen ] = useState(0);

    return (
        <section className="section section-bottom-0 bg-light !bg-transparent !mt-6 lg:!mt-5 min-h-full">
            <div className="container">
                <div className="mt-8 w-full flex flex-col lg:flex-row items-center justify-between space-y-10 lg:space-y-0 pb-6">
                    <div className="flex-1">
                        <div className="section-content flex flex-col items-center divide-x-10 max-w-[600px] w-full">
                            {benefits.map((item, key) => (
                                <AffiliateStep 
                                    key={key} 
                                    index={key}
                                    title={item.title}
                                    description={item.description}
                                    isOpen={isOpen == key}
                                    setIsOpen={setIsOpen}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="flex-1 text-center flex items-center justify-center">
                        <img src="/images/affiliate.png" className="animate-5 w-[90%] max-w-[600px] lg:w-[475px] mx-auto lg:!mx-0 drop-shadow-xl" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Affiliate;