import { ChatTwoTone, PublicTwoTone, SettingsTwoTone, SmartToyTwoTone, ThumbUpTwoTone, TrendingUpTwoTone } from "@mui/icons-material";
import DemoItem from "./DemoItem";
import { useState } from "react";


const DemoSection = () => {

    const [ opened, setOpened ] = useState(0);

    return (
        <section className="section section-bottom-0 bg-light !bg-transparent !mt-6 lg:!mt-0 min-h-full">
            <div className="container">
                <div className="section-head">
                    <div className="flex flex-col gap-5 items-center">
                        <div className="h-20 w-20 text-4xl hidden lg:flex items-center justify-center rounded-full overflow-hidden relative">
                            <div className="absolute left-0 top-0 w-full h-full bg-primary opacity-[0.3]"></div>
                            <SmartToyTwoTone className="text-primary !text-4xl" />
                        </div>
                        <div className="px-2 lg:px-0">
                            <h1 className="title h1 !text-4xl sm:!text-5xl tracking-[-1px] !text-gray-800 !-mt-1 text-center">Demo Chatbros</h1>
                            <p className="text-lg mt-3 text-center max-w-3xl mx-auto">Immerse yourself in an enriching experience: dive into chatbros' interactive demo, where a world of tailored conversations awaits to address your diverse needs, preferences, and interests</p>
                        </div>
                    </div>
                </div>
                <div className="shadow-lg rounded-2xl mx-auto p-3 bg-gray-100 mb-4 -mt-8 flex flex-col lg:flex-row gap-5 !w-auto mx-auto max-w-[750px]">
                    <iframe src={`${window.location.href.includes("localhost") ? `http://localhost:3001` : `https://widget.chatbros.ai`}/chat?id=KJDjEz2A&url=${window.location.href?.split("#")?.[0]}&open=true`} className="shrink-0 max-w-[400px] w-full border-0 h-[600px] rounded-2xl" />
                    <div className="flex flex-col w-full gap-3">
                        <DemoItem index={0} opened={opened} setOpened={setOpened} icon={<SmartToyTwoTone />} title="AI-Powered" description="The Chatbros chatbot is driven by artificial intelligence, allowing for intelligent and personalized conversations with visitors." />
                        <DemoItem index={1} opened={opened} setOpened={setOpened} icon={<SettingsTwoTone />} title="Customizable" description="Tailor the appearance and behavior of the chatbot to perfectly match your brand and website aesthetics." />
                        <DemoItem index={2} opened={opened} setOpened={setOpened} icon={<ChatTwoTone />} title="Seamless Assistance" description="Provide effortless and efficient support with the intuitive chatbot interface, simplifying customer communication." />
                        <DemoItem index={3} opened={opened} setOpened={setOpened} icon={<TrendingUpTwoTone />} title="Boost Conversions" description="Engage, convert, and drive sales with the intelligent chatbot, increasing website conversions." />
                        <DemoItem index={4} opened={opened} setOpened={setOpened} icon={<ThumbUpTwoTone />} title="Enhanced User Experience" description="Elevate website engagement and satisfaction using the AI-powered chatbot, improving the overall user experience." />
                        <DemoItem index={5} opened={opened} setOpened={setOpened} icon={<PublicTwoTone />} title="Unlimited Websites" description="Embed a single chatbot on multiple websites, optimizing customer support and engagement across all platforms." />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DemoSection;