import React, { useState } from "react";
import { AndroidTwoTone, ChatTwoTone, PublicTwoTone, SettingsTwoTone, SmartToyTwoTone, ThumbUpTwoTone, TrendingUpTwoTone, WifiTwoTone } from "@mui/icons-material";
import FAQ from "../home/FAQ";
import DemoSection from "./DemoSection";

const Demo = () => {

    return (
        <>
            <DemoSection />
            <FAQ />
        </>
    );
}

export default Demo;