import React, { useEffect, useState } from "react";

const BigTextSection = () => {

    const isMobile = window.innerWidth <= 712;
    const initialMarginLeft = !isMobile ? -window.innerWidth / 2 : -window.innerWidth * 2;
    const [ marginLeft, setMarginLeft ] = useState(initialMarginLeft);

    useEffect(() => {
        window.onscroll = (e) => {
            setMarginLeft(initialMarginLeft + window.scrollY / 2);
        }
    }, []);

    return (
        <div className="relative pb-20 pt-0">
            <div className="overflow-hidden whitespace-nowrap py-20">
                <h2 className="text-[40vw] lg:text-[25vw] leading-[.8em] tracking-[-10px] lg:tracking-[-20px] my-0 font-[700] text-[#d9dcff]" style={{ marginLeft: `${marginLeft}px` }}>AI Chatbot</h2>
            </div>
            <div className="flex flex-col-reverse lg:flex-row justify-end lg:justify-between px-4 lg:px-6 lg:!px-44 flex-wrap gap-6 lg:gap-0">
                <p class="text-22 font-medium leading-1/15em mb-1/15em lqd-unit-animation-done text-xl w-full max-w-[400px] text-center lg:!text-left"><span className="text-third">Engage more customers than ever,</span> <span>with a more reliable and cheaper solution designed to meet your needs.</span></p>
                <div className="flex justify-end"><img src="/images/hand-phone.png" className="max-w-[200px] lg:max-w-[260px] -mt-56 lg:-mt-64 lg:mr-20 animate-5" /></div>
            </div>
        </div>
    )
}

export default BigTextSection;