import { AddTwoTone, RemoveTwoTone } from "@mui/icons-material";
import React, { useState } from "react";

const AffiliateStep = (props) => {

    const {
        index,
        title,
        description,
        isOpen,
        setIsOpen
    } = props;

    return (
        <div className="flex flex-col w-full cursor-pointer" onClick={() => setIsOpen(index)}>
            <div className={`flex flex-col w-full ${isOpen && `rounded-t-lg bg-gradient-to-b from-purple-400/20 to-transparent`}`}>
                <div className="flex items-center justify-between p-4">
                    <h3 className="title h3 !text-xl !text-gray-700">{title}</h3>
                    {isOpen
                        ? <RemoveTwoTone className="!text-2xl !text-gray-800" /> 
                        : <AddTwoTone className="!text-2xl !text-gray-800" />
                    }
                </div>
            </div>
            <div className={`text-gray-600 transition-all overflow-hidden scale-[1] !h-0 px-4 ${isOpen && `!h-auto !scale-[1] pt-3 pb-5`}`}>
                {description}
            </div>
        </div>
    )
}

export default AffiliateStep;